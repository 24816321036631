import { LatLng } from '~/utility/geo/GeoLocation'
import { safeJSONParse } from '../../../utility/json'
import { SearchFilters } from '../../search/schemas'

interface QueryPart {
  bounds?: string
}

export function toQuery({ bounds }: SearchFilters): QueryPart | {} {
  return {
    bounds: bounds ? JSON.stringify(bounds) : undefined,
  }
}

export function fromQuery({ bounds }: QueryPart): {
  bounds?: { northEast: LatLng; southWest: LatLng }
} {
  return {
    bounds: bounds ? safeJSONParse(bounds) : undefined,
  }
}
